import styled from 'styled-components'
import { Checkbox } from 'semantic-ui-react'
import { Container } from '../styles/LoginPage.styles'

export const IframeForm = styled.iframe`
  height: 666px;
  width: 100%;
  border-style: none;
  margin-top: 20px;
`

export const AddCardContainer = styled(Container)`
  padding: 0 10px;
  flex-direction: column;
  @media ${({ theme }) => theme.device.sm} {
    padding: 0 25%;
  }
`

export const SaveCheckbox = styled(Checkbox)`
  align-self: start;
  padding-top: 10px;
`
